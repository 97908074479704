const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://me.nzmdn.me',
  title: 'NZ.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Nizamuddin Mohamed',
  role: 'Software Engineer',
  description:
    'Specializing in artificial intelligence, I have a passion for using technology to solve complex problems and am always looking for new ways to apply my skills in the field of AI.\n\n' +
    'In my professional career, I have worked on a variety of projects ranging from natural language processing to computer vision.I am constantly learning and staying up to date with the latest developments in the field, and I am excited to bring my expertise to new challenges.\n\n' +
    'In my free time, I am an avid photographer.Photography allows me to combine my love of technology with my creative side, and I enjoy exploring new techniques and styles to capture the world around me.\n\n' +
    'I hope you enjoy exploring my website and learning more about my work and interests.Feel free to reach out if you have any questions or would like to collaborate on a project.',
  resume: 'https://drive.google.com/file/d/0B_Avr6umXIJhOW51bS1NbjdtQXgyNklTdm1KNWNCNTJMaUk4/view?usp=share_link&resourcekey=0-Ffu50RBPTfDcuUy_5GIIqA',
  social: {
    linkedin: 'https://www.linkedin.com/in/nizamuddinmohamed/',
    github: 'https://github.com/webnizam/',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Automatic Number Plate Detection System',
    description:
      'A small repository to provide an example of how to detect license plates in a video file, video stream or static image. Isolate the plate with a bounding box, extract the contents of the bounding box and then using OCR extract the text on the plate. Using the extraced text we write the output back to the image/frame.',
    stack: ['Python', 'HTML', 'JavaScript'],
    sourceCode: 'https://github.com/webnizam/anpr-falcon-ai',
    livePreview: 'https://github.com/webnizam/anpr-falcon-ai',
  },
  {
    name: 'Android Payload Generator',
    description:
      'This tool helps to generate android payload with the help of QT5. It has a cool and simple graphical user interface which will make generating payload and listening for reverse shell easier.',
    stack: ['Python', 'PyQT'],
    sourceCode: 'https://github.com/webnizam/android-payload-generator',
    livePreview: 'https://github.com/webnizam/android-payload-generator',
  },
  {
    name: 'Vehicle Damage Analyzer',
    description:
      'Program to Analyze the percentage of damage in a vehicle photo with the help of tensorflow',
    stack: ['Python', 'JavaScript', 'HTML', 'CSS'],
    sourceCode: 'https://github.com/webnizam/vehicle-damage-analyzer',
    livePreview: 'https://github.com/webnizam/vehicle-damage-analyzer',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'Kotlin',
  'Java',
  'Swift',
  'JavaScript',
  'TypeScript',
  'Angular',
  'Docker',
  'Git',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'webnizam@mail.com',
}

export { header, about, projects, skills, contact }
